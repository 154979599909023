import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CanadaHealthPage = ({ pageContext }) => {

  return (
    <Layout>
      <Seo title="Canada" />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>Canada / Health</h1>
        </div>
      </main>
    </Layout>
  )
}

export default CanadaHealthPage
